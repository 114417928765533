import React, { Component } from 'react';
import { Route } from 'react-router';

import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login } from './components/Account/Login';
import { Account } from './components/Account/Account';
import { Register } from './components/Account/Register';
import { Verify } from './components/Account/Verify';
import { TradeUsers } from './components/Trade/TradeUsers';
import { TradeUser } from './components/Trade/TradeUser';
import { TradeRequests } from './components/Trade/TradeRequests';
import Collection from './components/Collection/Collection';
import { AuthContext } from './context/Context'

import './custom.css'

const App = () => {
    //static displayName = App.name;

    const [authState, setState] = React.useState(false)

    return (
        <AuthContext.Provider value={{ authState, setState }}>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/collection/:collectionId' component={Collection} />
                <Route path='/login' component={Login} />
                <Route path='/Account' component={Account} />
                <Route path='/verify/:userId/:verifyToken' component={Verify} />
                <Route path='/register' component={Register} />
                <Route path='/Trade/:collectionId' component={TradeUsers} />
                <Route path='/Tradeuser/:collectionId/:tradeUserId' component={TradeUser} />
                <Route path='/Traderequests/:collectionId/' component={TradeRequests} />
            </Layout>
        </AuthContext.Provider>
    );
}

export default App;
