import React, { useState, useEffect } from 'react'
import ApiServices from '../../services/ApiServices';
import { useParams } from 'react-router-dom';

export const Verify = () => {
    const [ verifyState, setVerifiedState ] = useState(false)
    const { userId } = useParams();
    const { verifyToken } = useParams();

    useEffect(() => {
        verifyAccount();
    }, [])

    const verifyAccount = () => {
        ApiServices.verifyAccount(userId, verifyToken)
            .then((result) => {
                setVerifiedState(result.data.status == 'Success');
            })
    };

    return (
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-md-9">
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                <div class="col-lg-6">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">{verifyState ? "Account bevestigd" : "Ongeldige gegevens"}</h1>
                                        </div>
                                        <div className="text-left">
                                            {verifyState ? "Je account is bevestigd, je kunt nu inloggen." : "Helaas, er is iets misgegaan in de bevestiging van het account."}
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verify;
