import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Collections from './Collection/Collections';
import CollectionDetail from './Collection/CollectionDetail';
import ApiServices from '../services/ApiServices';

export const Home = () => {
    const [user, setUser] = useState();
    const [userHasCollections, setUserHasCollections] = useState(true);
    const [changedCollection, setChangedCollection] = useState(false);
    const history = useHistory();

    function onOpenCollection(collection) {
        selectCollection(collection);
    }

    function changedUserHasCollections(userHasCollections) {
        setUserHasCollections(userHasCollections);
    }

    function AddToMyCollection(collection) {
        ApiServices.addToMyCollection(user.userAccountId, collection.collectionId)
            .then((result) => {
                if (result.data.status == 'Success') {
                    collection.isMyCollection = true;

                    selectCollection(null);
                    setUserHasCollections(true);
                    setChangedCollection(!changedCollection);
                }
                else
                    alert('Er is iets mis gegaan.');
            })
    }

    const [selectedCollection, selectCollection] = useState('selectedCollection');

    useEffect(() => {
        selectCollection(null);

        const loggedInUser = localStorage.getItem("user");

        if (loggedInUser) {
            var currentUser = JSON.parse(loggedInUser)
            setUser(currentUser);
        }

    }, [])

    return (
        <div>
            <div className="headerHome">
                <h1>Plakplaatjes ruilen</h1>
                <p>Spaar hier mee aan supermarktacties en maak je plakboek compleet door met elkaar te ruilen.</p>
                {!user && (<><button className="btn btn_register" onClick={() => history.push('/register')}>Maak account</button>&nbsp;<button className="btn btn_login" onClick={() => history.push('/login')}>Inloggen</button></>)}
            </div>
            {(user && userHasCollections && <div><h2>Mijn collecties:</h2>
                <Collections onOpenCollection={onOpenCollection} isMyCollections={true} changedUserHasCollections={changedUserHasCollections} key={changedCollection} /></div>)}

            <br/>
            <br />
            <hr />
                <br />
                <br />

            <h2>Andere spaaracties:</h2>
            <Collections onOpenCollection={onOpenCollection} isMyCollections={false} key={ changedCollection } />

            {selectedCollection != null && (
                <div className="selectedCollectionDetails">
                    <CollectionDetail collection={selectedCollection} />
                    {!user && (<p>Meld je aan om mee te sparen.<br /><button className="btn btn_register" onClick={() => history.push('/register')}>Maak account</button>&nbsp;<button className="btn btn_login" onClick={() => history.push('/login')}>Inloggen</button></p>)}

                    {selectedCollection != null && !selectedCollection.isMyCollection && user && (<input type='button' className="btn" value='Voeg toe aan mijn collecties' onClick={() => AddToMyCollection(selectedCollection)} />)}
                </div>)}

        </div>
    );
}

export default Home;