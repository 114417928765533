import React, { useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/Context'

import './NavMenu.css';


export const NavMenu = () => {
    //static displayName = NavMenu.name;
    const { authState, setState } = useContext(AuthContext);
    const { collapsed, toggleNavbar } = useState(true);
    const [user, setUser] = useState();

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");

        if (loggedInUser) {
            var currentUser = JSON.parse(loggedInUser)
            setUser(currentUser);
            setState(true);
        }
    }, [])

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">PlakplaatjesRuilen.nl</NavbarBrand>
                    <NavbarToggler onClick={() => toggleNavbar(!collapsed)} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {authState &&
                                (<>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/">Mijn collecties</NavLink>
                                    </NavItem></>) } 

                            {!authState &&
                                (<><NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/Login">Inloggen</NavLink>
                                </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/Register">Maak account</NavLink>
                                    </NavItem></>)}

                            {authState && user && (<><NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/Account">{user.displayName}</NavLink>
                            </NavItem></>)}
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );

}