import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CollectionDetail from '../Collection/CollectionDetail';
import ApiServices from '../../services/ApiServices';


export const TradeUsers = () => {
    const [tradeUsers, setTradeUsers] = useState([]);
    //const [user, setUser] = useState();
    const { collectionId } = useParams();
    const [collection, setCollection] = useState('');

    const history = useHistory();

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {

        const loggedInUser = localStorage.getItem("user");
        var currentUser = null;

        if (loggedInUser) {
            currentUser = JSON.parse(loggedInUser)
            //setUser(currentUser);
        }
        //onOpenCollection(collection, isMyCollections)

        ////const response = await axios.get(configData.SERVER_URL + 'Collections/getCollection/' + collectionId);
        //const responseCollection = await fetch(configData.SERVER_URL + 'Collections/getCollection/' + collectionId);
        ////const response = await ApiServices.getCollection();
        //const collectionData = await responseCollection.json();
        //setCollection(collectionData);
        const collectionData = await ApiServices.getCollection(collectionId);
        setCollection(collectionData);

        const data = await ApiServices.getTradeUsers(currentUser.userAccountId, collectionId);
        setTradeUsers(data);
    }

    return (
        <div className="tradeUsersContainer">
            <CollectionDetail collection={collection} />

            <div className="collectionButtons">
                <button className="btn" onClick={() => history.push('/collection/' + collection.collectionId)}>Collectie</button> <button className="btn" onClick={() => history.push('/traderequests/' + collection.collectionId)}>Ruil verzoeken</button>
            </div>

            <div className="tradeUsers">
                <h2>Ruilen</h2>
                <p>Kijk hier met welke andere spaarder je kunt ruilen om je verzameling compleet te krijgen.<br />
                    Selecteer een gebruiker om te kijken welke plaatjes je met elkaar kunt ruilen.</p>
            {
                    tradeUsers && tradeUsers.length > 0 ? (tradeUsers.map((tradeUser) => (
                <div key={tradeUser.user.userAccountId} className="tradeuser" onClick={() => history.push('/Tradeuser/' + collectionId + '/' + tradeUser.user.userAccountId)}>
                    <b>{tradeUser.user.displayName}</b><br/>
                    Heeft voor jou {tradeUser.doubleCollectionItems.length} plaatjes:&nbsp;
                    <b>{tradeUser.doubleCollectionItems.map((doubleCollectionItem) => (<i key={doubleCollectionItem.number}>{doubleCollectionItem.number}</i>)).reduce((result, item) => [result, <span key={item.number + '_s'}>, </span>, item])}</b>
                </div>
            ))) : <i>Er zijn nu geen gebruikers die plaatjes voor je dubbel hebben.</i>
                }</div></div>
    );
}

export default TradeUsers;