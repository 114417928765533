//https://ernestoangulo.hashnode.dev/manage-global-states-with-context-api-and-usecontext
//https://stackoverflow.com/questions/71960194/update-navbar-after-success-login-or-logout-redirection


// Import createContext
import { createContext } from 'react';

// AuthContext with false as default value
export const AuthContext= createContext({
    auth: false,
    //setAuth: () => { },
    user: null,
});

