import React, { useState, useEffect } from 'react';
import configData from "../../config.json";
import ApiServices from '../../services/ApiServices';
import CollectionDetail from './CollectionDetail';

import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function Collection() {
    const [collectionItems, setCollectionItems] = useState([]);
    const [collection, setCollection] = useState('');
    const [user, setUser] = useState();
    const { collectionId } = useParams();

    const history = useHistory();

    useEffect(() => {
        loadData();
    }, [])


    const loadData = async () => {
        const loggedInUser = localStorage.getItem("user");

        if (loggedInUser) {
            var currentUser = JSON.parse(loggedInUser)
            setUser(currentUser);

            const collectionData = await ApiServices.getCollection(collectionId);
            setCollection(collectionData);

            if (collectionData && currentUser) {
                const data = await ApiServices.getCollectionItems(currentUser.userAccountId, collectionId);
                setCollectionItems(data);
            }
        }
    }

    function SetItemOwned(item) {
        if (item) {
            ApiServices.setItemOwned(user.userAccountId, item.collectionItemId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        item.owned = true;
                        item.numberOfItems = 1;
                        UpdateItemInState(item);
                    }
                    else
                        alert('Invalid User');
                })
        }
    }

    function IncreaseNumberOfItems(item) {
        if (item) {
            ApiServices.increaseNumberOfItems(user.userAccountId, item.collectionItemId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        item.numberOfItems++;
                        UpdateItemInState(item);
                    }
                    else
                        alert('error');
                })
        }
    }

    function DecreaseNumberOfItems(item) {
        if (item) {
            ApiServices.decreaseNumberOfItems(user.userAccountId, item.collectionItemId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        item.numberOfItems--;
                        UpdateItemInState(item);
                    }
                    else
                        alert('error');
                })
        }
    }

    function ToggleCollection(item) {
        if (item.owned)
            return;

        SetItemOwned(item);
    }

    function UpdateItemInState(item) {
        const index = collectionItems.findIndex(emp => emp.collectionItemId === item.collectionItemId),
            copyItems = [...collectionItems] // important to create a copy, otherwise you'll modify state outside of setState call
        copyItems[index] = item;
        setCollectionItems(copyItems);
    }

    return (
        <div className="collectionContainer">
            <CollectionDetail collection={collection} />

            <div className="collectionButtons">
                <button className="btn" onClick={() => history.push('/trade/' + collection.collectionId)}>Ruilen</button> <button className="btn" onClick={() => history.push('/traderequests/' + collection.collectionId)}>Ruil verzoeken</button>
            </div>

            <div className="collectionItems">
                {
                    collectionItems.map((item) => (
                        <div className="collectionItem" key={item.collectionItemId}>
                            <img className={item.owned ? 'owned' : ''} src={configData.BLOB_COLLECTIONS_URL + collection.collectionId + '/' + item.number + '.jpg'} onClick={() => ToggleCollection(item)} /><br />
                            <b>{item.number}</b><br />

                            {item.owned ? <div>
                                {item.numberOfItems > 1 && (<input type='button' value='-' className='btn qtybtn' field='quantity' onClick={() => DecreaseNumberOfItems(item)} />)}
                                <input type='text' name={item.number} value={item.numberOfItems} className='qty' readOnly="readOnly" />
                                <input type='button' value='+' className='btn qtybtn' field='quantity' onClick={() => IncreaseNumberOfItems(item)} />
                            </div> : <div height="30px">&nbsp;</div>}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}


export default Collection;