//https://loizenai.com/reactjs-jwt-authentication-example/

import axios from 'axios';
import configData from "../config.json";

// Add a request interceptor
//axios.interceptors.request.use(config => {
//    const user = JSON.parse(localStorage.getItem('user'));

//    if (user && user.accessToken) {
//        const token = 'Bearer ' + user.accessToken;
//        config.headers.Authorization = token;
//    }

//    return config;
//});

class ApiServices {
    /**** Account ****/
    async verifyAccount(userAccountId, verifyToken) {
        return await axios.post(configData.SERVER_URL + 'Account/VerifyAccount', null, {
            params: {
                userAccountId,
                verifyToken
            }
        });
    }

    async login(loginData) {
        return await axios.post(configData.SERVER_URL + 'Account/Login', loginData);
    }


    async addUserAccount(registerData) {
        return await axios.post(configData.SERVER_URL + 'Account/addUserAccount', registerData);
    }

    /**** Collection ****/
    async getCollection(collectionId) {
        const response = await axios.get(configData.SERVER_URL + 'Collections/getCollection/' + collectionId);
        return await response.data;
    }

    async getCollectionItems(userAccountId, collectionId) {
        const response = await axios.get(configData.SERVER_URL + 'Collections/getCollectionItems/' + userAccountId + '/' + collectionId);
        return await response.data;
    }

    async setItemOwned(userAccountId, collectionItemId) {

        return await axios.post(configData.SERVER_URL + 'Collections/SetItemOwned', null, {
            params: {
                userAccountId,
                collectionItemId
            }
        });
    }

    async increaseNumberOfItems(userAccountId, collectionItemId) {

        return await axios.post(configData.SERVER_URL + 'Collections/IncreaseNumberOfItems', null, {
            params: {
                userAccountId,
                collectionItemId
            }
        });
    }

    async decreaseNumberOfItems(userAccountId, collectionItemId) {
        return await axios.post(configData.SERVER_URL + 'Collections/DecreaseNumberOfItems', null, {
            params: {
                userAccountId,
                collectionItemId
            }
        });
    }

    async addToMyCollection(userAccountId, collectionId) {
        return await axios.post(configData.SERVER_URL + 'Collections/AddToMyCollection', null, {
            params: {
                userAccountId,
                collectionId
            }
        });
    }

    /**** Trading ****/

    async getMatchTradeUser(userAccountId, collectionId, tradeUserId) {
        const response = await axios.get(configData.SERVER_URL + 'trade/' + 'GetMatchTradeUser/' + userAccountId + '/' + tradeUserId + '/' + collectionId);
        return await response.data;
    }

    async makeTradeRequest(tradeRequestData) {
        //return await axios.post(configData.SERVER_URL + 'trade/MakeTradeRequest', tradeRequestData);
        return await axios.post(configData.SERVER_URL + 'trade/MakeTradeRequest', tradeRequestData
            , {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
    }

    async getTradeUsers(userAccountId, collectionId) {
        const response = await axios.get(configData.SERVER_URL + 'trade/GetTradeUsers/' + userAccountId + '/' + collectionId);
        return await response.data;
    }

    async getTradeRequests(userAccountId, collectionId) {
        const response = await axios.get(configData.SERVER_URL + 'trade/GetTradeRequests/' + userAccountId + '/' + collectionId);
        return await response.data;
    }

    async cancelTradeRequest(tradeRequestId) {
        return await axios.post(configData.SERVER_URL + 'trade/CancelTradeRequest', null, {
            params: {
                tradeRequestId
            }
        });
    }

    async approveTradeRequest(tradeRequestId) {
        return await axios.post(configData.SERVER_URL + 'trade/ApproveTradeRequest', null, {
            params: {
                tradeRequestId
            }
        });
    }

    async rejectTradeRequest(tradeRequestId) {
        return await axios.post(configData.SERVER_URL + 'trade/RejectTradeRequest', null, {
            params: {
                tradeRequestId
            }
        });
    }

    async tradeRequestItemSend(tradeRequestId, userAccountId) {
        return await axios.post(configData.SERVER_URL + 'trade/TradeRequestItemSend', null, {
            params: {
                tradeRequestId,
                userAccountId
            }
        });
    }

    async tradeRequestItemReceived(tradeRequestId, userAccountId) {
        return await axios.post(configData.SERVER_URL + 'trade/TradeRequestItemReceived', null, {
            params: {
                tradeRequestId,
                userAccountId
            }
        });
    }
} 

export default new ApiServices();