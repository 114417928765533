import React, { useState } from 'react'
import ApiServices from '../../services/ApiServices';


export const Register = () => {
    const [data, setdata] = useState({
        Email: '',
        Password: '',
        PasswordRepeat: '',
        DisplayName: '',
        PostName: '',
        PostAddress: '',
        PostCode: '',
        PostCity: ''
    });

    const [isError, setIsError] = useState({
        DisplayName: '',
        Email: '',
        Password: '',
        PasswordRepeat: '',
        PostName: '',
        PostAddress: '',
        PostCode: '',
        PostCity: ''
    });

    const [isRegistered, setIsRegistered] = useState(false);
    const [submitError, setSubmitError] = useState('');

    const regExp = RegExp(
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
    )


    const Registration = (e) => {
        e.preventDefault();

        const isErrorCopy = isError;
        Object.entries(data).map(([key, val]) => {
            if (val.length == 0) {
                isErrorCopy[key] = "Dit is een verplicht veld"
            }
            else {
                isErrorCopy[key] = ""
            }
        });
        setIsError({ ...isErrorCopy });

        if (formValid()) {
            const data1 = {
                Email: data.Email,
                Password: data.Password,
                DisplayName: data.DisplayName,
                PostName: data.PostName,
                PostAddress: data.PostAddress,
                PostCode: data.PostCode,
                PostCity: data.PostCity,
            };

            ApiServices.addUserAccount(data1)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        setSubmitError('');
                        setIsRegistered(true);
                    }
                    else {
                        setSubmitError('Fout bij het aanmaken van account: ' + result.data.message);
                    }
                })
        } else {
            console.log("Form is invalid!");
        }
    }

    const onChange = (e) => {
        e.persist();
        setdata({ ...data, [e.target.name]: e.target.value });

        const { name, value } = e.target;
        const isErrorCopy = isError;
   
        switch (name) {
            case "DisplayName":
                isErrorCopy.DisplayName =
                    value.length < 4 ? "Minimaal 6 karakters voor je gebruikersnaam" : "";
                break;
            case "Email":
                isErrorCopy.Email = regExp.test(value)
                    ? ""
                    : "Ongeldig e-mail adres";
                break;
            case "Password":
                isErrorCopy.Password =
                    value.length < 6 ? "Minimaal 6 karakters voor je wachtwoord" : "";
                break;
            case "PasswordRepeat":
                isErrorCopy.PasswordRepeat =
                    value !== data.Password ? "De ingevoerde wachtwoorden komen niet overeen" : "";
                break;
            default:
                break;
        }
        debugger;
        setIsError(isErrorCopy);
    }


    const formValid = () => {
        let isValid = true;
        debugger;
        Object.values(isError).forEach(val => {
            if (val.length > 0) {
                isValid = false
            }
        });

        return isValid;
    };


    return (
        <div className="container">
            <div className="card o-hidden border-0 shadow-lg my-5" style={{ "marginTop": "5rem!important;" }}>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="p-5">
                                <div className="text-center">
                                    <h1 className="h4 text-gray-900 mb-4">Account aanmaken</h1>
                                </div>

                                {!isRegistered ? <form onSubmit={Registration} class="user">
                                    <div className="text-left">
                                        <h1 className="h4 text-gray-900 mb-4">Inlog gegevens</h1>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" name="Email" onChange={onChange} value={data.Email} className={isError.Email.length > 0 ? " is-invalid form-control" : "form-control"} id="exampleFirstName" placeholder="E-mail" />
                                            {isError.Email.length > 0 && (
                                                <span className="invalid-feedback">{isError.Email}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input type="Password" name="Password" onChange={onChange} value={data.Password} className={isError.Password.length > 0 ? " is-invalid form-control" : "form-control"} id="password" values={isError.Email} placeholder="Wachtwoord" />
                                            {isError.Password.length > 0 && (
                                                <span className="invalid-feedback">{isError.Password}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input type="Password" name="PasswordRepeat" onChange={onChange} value={data.PasswordRepeat} className={isError.PasswordRepeat.length > 0 ? " is-invalid form-control" : "form-control"} id="repeatpassword" placeholder="Herhaal wachtwoord" />
                                            {isError.PasswordRepeat.length > 0 && (
                                                <span className="invalid-feedback">{isError.PasswordRepeat}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <input type="text" name="DisplayName" onChange={onChange} value={data.DisplayName} className={isError.DisplayName.length > 0 ? " is-invalid form-control" : "form-control"} id="exampleInputEmail" placeholder="Gebruikersnaam" />
                                            {isError.DisplayName.length > 0 && (
                                                <span className="invalid-feedback">{isError.DisplayName}</span>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="text-left">
                                        <h1 className="h4 text-gray-900 mb-4">Adresgegevens</h1>
                                        <i>Deze gegevens worden alleen gedeeld om plaatjes naar je toe te kunnen sturen.    </i>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="PostName" onChange={onChange} value={data.PostName} className={isError.PostName.length > 0 ? " is-invalid form-control" : "form-control"} id="exampleInputEmail" placeholder="Voor-en achternaam" />
                                        {isError.PostName.length > 0 && (
                                            <span className="invalid-feedback">{isError.PostName}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="PostAddress" onChange={onChange} value={data.PostAddress} className={isError.PostAddress.length > 0 ? " is-invalid form-control" : "form-control"} id="exampleInputEmail" placeholder="Straat en huisnummer" />
                                        {isError.PostAddress.length > 0 && (
                                            <span className="invalid-feedback">{isError.PostAddress}</span>
                                        )}
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="PostCode" onChange={onChange} value={data.PostCode} className={isError.PostCode.length > 0 ? " is-invalid form-control" : "form-control"} id="exampleInputEmail" placeholder="Postcode" />
                                        {isError.PostCode.length > 0 && (
                                            <span className="invalid-feedback">{isError.PostCode}</span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="PostCity" onChange={onChange} value={data.PostCity} className={isError.PostCity.length > 0 ? " is-invalid form-control" : "form-control"} id="exampleInputEmail" placeholder="Plaats" />
                                        {isError.PostCity.length > 0 && (
                                            <span className="invalid-feedback">{isError.PostCity}</span>
                                        )}
                                    </div>
                                    {submitError !== '' && (
                                        <div class="text-left">
                                            <span className="error text-danger"><p>{submitError}</p></span>
                                        </div>
                                    )}
                                    <button type="submit" className="btn btn-primary  btn-block"  >
                                        Aanmelden
                                    </button>
                                </form> : <div>Het account is aangemaakt en een mail is verzonden om het registreren te bevestigen.</div>}
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register  