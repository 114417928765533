import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/Context'
import ApiServices from '../../services/ApiServices';

export const Login = (props) => {
    const [employee, setemployee] = useState({ Email: '', Password: '' });

    const { authState, setState } = useContext(AuthContext)
    const history = useHistory();

    const Login = (e) => {
        e.preventDefault();

        ApiServices.login({ Email: employee.Email, Password: employee.Password })
            .then((result) => {
                if (result.data.status == '200') {
                    localStorage.setItem('user', JSON.stringify(result.data.userDetails));
                    setState(true);

                    props.history.push('/')
                }
            })
    };

    const onChange = (e) => {
        e.persist();
        setemployee({ ...employee, [e.target.name]: e.target.value });
    }

    return (

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-md-9">
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block bg-login-image">
                                    <div class="p-5">
                                        <div class="text-left">
                                            <p>Of maak een nieuw account:</p>
                                            <button className="btn btn_register" onClick={() => history.push('/register')}>Maak account</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">Inloggen</h1>
                                        </div>
                                        <form onSubmit={Login} class="user">
                                            <div class="form-group">
                                                <input type="email" class="form-control" value={employee.Email} onChange={onChange} name="Email" id="Email" aria-describedby="emailHelp" placeholder="E-mail" />
                                            </div>
                                            <div class="form-group">
                                                <input type="password" class="form-control" value={employee.Password} onChange={onChange} name="Password" id="Password" placeholder="Wachtwoord" />
                                            </div>
                                            {/* <div class="form-group">  
                          <div class="custom-control custom-checkbox small">  
                            <input type="checkbox" class="custom-control-input" id="customCheck"/>  
                            <label class="custom-control-label" for="customCheck">Remember Me</label>  
                          </div>  
                        </div> */}
                                            <button type="submit" className="btn btn-info mb-1" block><span>Inloggen</span></button>
                                            <hr />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
