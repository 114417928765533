import React, { useState, useEffect } from 'react';
import configData from "../../config.json";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CollectionDetail from '../Collection/CollectionDetail';
import ApiServices from '../../services/ApiServices';

export const TradeUser = (props) => {
    const [tradeUserData, setTradeUserData] = useState();
    const [doubleCollectionItems, setDoubleCollectionItems] = useState([]);
    const [matchingCollectionItems, setMatchingCollectionItems] = useState([]);
    const [user, setUser] = useState();
    const [collection, setCollection] = useState('');

    const { collectionId } = useParams();
    const { tradeUserId } = useParams();

    const history = useHistory();


    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        const loggedInUser = localStorage.getItem("user");
        var currentUser = null;

        if (loggedInUser) {
            currentUser = JSON.parse(loggedInUser);
            setUser(currentUser);
        }

        const collectionData = await ApiServices.getCollection(collectionId);
        setCollection(collectionData);

        const tradeUserData = await ApiServices.getMatchTradeUser(currentUser.userAccountId, collectionId, tradeUserId);
        setTradeUserData(tradeUserData);
        setDoubleCollectionItems(tradeUserData.doubleCollectionItems);
        setMatchingCollectionItems(tradeUserData.matchingCollectionItems);
    }

    function ToggleTradeWantedItem(item) {
        const index = doubleCollectionItems.findIndex(emp => emp.collectionItemId === item.collectionItemId),
            copyDoubleCollectionItems = [...doubleCollectionItems]

        if (copyDoubleCollectionItems[index].wantToTrade)
            item.wantToTrade = false;
        else
            item.wantToTrade = true;

        copyDoubleCollectionItems[index] = item;
        setDoubleCollectionItems(copyDoubleCollectionItems);
    }

    function ToggleTradeGiveItem(item) {
        const index = matchingCollectionItems.findIndex(emp => emp.collectionItemId === item.collectionItemId),
            copyMatchingCollectionItems = [...matchingCollectionItems]

        if (copyMatchingCollectionItems[index].wantToTrade)
            item.wantToTrade = false;
        else
            item.wantToTrade = true;

        copyMatchingCollectionItems[index] = item;
        setMatchingCollectionItems(copyMatchingCollectionItems);
    }

    function SentRequest() {

        var tradeRequestData = {
            userOwnerAccountId: user.userAccountId,
            userRequestAccountId: tradeUserId,
            collectionId: collectionId,
            doubleCollectionItems: doubleCollectionItems.filter(item => item.wantToTrade),
            matchingCollectionItems: matchingCollectionItems.filter(item => item.wantToTrade)
        }

        if (tradeRequestData.doubleCollectionItems && tradeRequestData.doubleCollectionItems.length > 0 && tradeRequestData.matchingCollectionItems && tradeRequestData.matchingCollectionItems.length > 0) {
            ApiServices.makeTradeRequest(tradeRequestData).then((result) => {
                if (result.data.status == 'Success') {
                    props.history.push('/traderequests/' + collectionId)
                }
            })
        }
        else {
            alert('selecteer eerst plaatjes om te ruilen');
        }

        //axios.post(configData.SERVER_URL + 'trade/MakeTradeRequest', tradeRequest
        //    , {
        //        headers: {
        //            'Content-Type': 'application/json'
        //        },
        //    })
        //    .then((result) => {
        //        if (result.data.status == 'Success') {
        //        }
        //        else
        //            alert('Error');
        //    })
    }

    return (
        <div className="tradeUsersContainer">
            <CollectionDetail collection={collection} />

            <div className="collectionButtons">
                <button className="btn" onClick={() => history.push('/collection/' + collection.collectionId)}>Collectie</button> <button className="btn" onClick={() => history.push('/traderequests/' + collection.collectionId)}>Ruil verzoeken</button>
            </div>

            <div className="tradeUsers">
                <h1>Ruilen</h1>
                <p>Selecteer de plaatjes die je wilt ruilen en doe een ruil voorstel</p>

                {tradeUserData && tradeUserData.user && doubleCollectionItems ?
                    <div>
                        <p>{tradeUserData.user.displayName} heeft voor jou dubbel:</p>
                        <div>{doubleCollectionItems.map((item) => (
                            <div key={item.collectionItemId} className="collectionItem" onClick={() => ToggleTradeWantedItem(item)}>
                                <img className={item.wantToTrade ? 'wantToTrade' : ''} src={configData.BLOB_COLLECTIONS_URL + collectionId + '/' + item.number + '.jpg'} /><br />
                                <b>{item.number}</b>
                            </div>))}
                        </div>
                    </div>
                    : <p>Heeft geen dubbele plaatjes voor je</p>}

                {
                    tradeUserData && tradeUserData.user ?
                        tradeUserData && tradeUserData.user && tradeUserData.matchingCollectionItems && tradeUserData.matchingCollectionItems.length > 0 ?
                            <div>
                                <p>Jij hebt voor {tradeUserData.user.displayName} dubbel:</p>
                                <div>{tradeUserData.matchingCollectionItems.map((item) => (
                                    <div key={item.collectionItemId} className="collectionItem" onClick={() => ToggleTradeGiveItem(item)}>
                                        <img className={item.wantToTrade ? 'wantToTrade' : ''} src={configData.BLOB_COLLECTIONS_URL + collectionId + '/' + item.number + '.jpg'} /><br />
                                        <b>{item.number}</b>
                                    </div>))}
                                </div>
                            </div>
                            : <p>Jij hebt geen dubbele plaatjes voor {tradeUserData.user.displayName}</p>

                        : <p></p>}
                <button className="btn" onClick={() => SentRequest()}>Verstuur ruilvoorstel</button>
            </div>
        </div>
    );
}

export default TradeUser;