import React from 'react';
import configData from "../../config.json";

function CollectionDetail({ collection}) {
  
    return (
        collection && (<div className="collectionDetails">
            <h2>{collection.name}</h2>
            <p>Actie {collection.endDate && new Date(collection.endDate) < new Date() ? "liep" : "loopt"} van {collection.startDate ? new Date(collection.startDate).toLocaleDateString() : ''} tot {collection.endDate ? new Date(collection.endDate).toLocaleDateString() : ''}</p>
            {collection.imageFile && (<p><img height='250px' src={configData.BLOB_COLLECTION_URL + collection.imageFile} /></p>)}
        </div> )
    );
}


export default CollectionDetail;