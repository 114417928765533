import React, { useState, useEffect } from 'react';
import configData from "../../config.json";
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CollectionDetail from '../Collection/CollectionDetail';
import ApiServices from '../../services/ApiServices';

export const TradeRequests = () => {
    const [tradeRequests, setTradeRequests] = useState([]);
    const [user, setUser] = useState();
    const { collectionId } = useParams();
    const [collection, setCollection] = useState('');

    const history = useHistory();

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {

        const loggedInUser = localStorage.getItem("user");
        var currentUser = null;

        if (loggedInUser) {
            currentUser = JSON.parse(loggedInUser)
            setUser(currentUser);
        }

        const collectionData = await ApiServices.getCollection(collectionId);
        setCollection(collectionData);

        const data = await ApiServices.getTradeRequests(currentUser.userAccountId, collectionId);
        setTradeRequests(data);
        console.log(data);
    }

    function UpdateItemInState(item) {
        debugger;
        const index = tradeRequests.findIndex(emp => emp.tradeRequestId === item.tradeRequestId),
            copyItems = [...tradeRequests] // important to create a copy, otherwise you'll modify state outside of setState call
        copyItems[index] = item;
        setTradeRequests(copyItems);
    }

    function CancelTradeRequest(item) {
        if (item) {
            ApiServices.cancelTradeRequest(item.tradeRequestId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        item.tradeStatus = configData.REQUEST_STATUS_ENUM.Canceled;
                        UpdateItemInState(item);
                    }
                    else
                        alert('error');
                })
        }
    }

    function ApproveTradeRequest(item) {
        if (item) {
            //var tradeRequestId = item.tradeRequestId;
            //axios.post(configData.SERVER_URL + 'trade/ApproveTradeRequest', null, {
            //    params: {
            //        tradeRequestId
            //    }
            //})
            ApiServices.approveTradeRequest(item.tradeRequestId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        item.tradeStatus = configData.REQUEST_STATUS_ENUM.Approved;
                        UpdateItemInState(item);
                    }
                    else
                        alert('error');
                })
        }
    }

    function RejectTradeRequest(item) {
        if (item) {
            //var tradeRequestId = item.tradeRequestId;
            //axios.post(configData.SERVER_URL + 'trade/RejectTradeRequest', null, {
            //    params: {
            //        tradeRequestId,
            //    }
            //})
            ApiServices.rejectTradeRequest(item.tradeRequestId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        item.tradeStatus = configData.REQUEST_STATUS_ENUM.Rejected;
                        UpdateItemInState(item);
                    }
                    else
                        alert('error');
                })
        }
    }

    function ItemsSend(item) {
        if (item) {
            ApiServices.tradeRequestItemSend(item.tradeRequestId, user.userAccountId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        if (item.userOwnerAccountId === user.userAccountId)
                            item.sentByUserOwner = true;
                        else
                            item.sentByUserRequest = true;

                        UpdateItemInState(item);
                    }
                })
        }
    }

    function ItemsReceived(item) {
        if (item) {
            ApiServices.tradeRequestItemReceived(item.tradeRequestId, user.userAccountId)
                .then((result) => {
                    if (result.data.status == 'Success') {
                        if (result.data.status == 'Success') {
                            if (item.userOwnerAccountId === user.userAccountId)
                                item.receivedByUserOwner = true;
                            else
                                item.receivedByUserRequest = true;

                            UpdateItemInState(item);
                        }
                    }
                })
        }
    }

    function IsActiveTrade(traderequest) {
        return traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.RequestSent || (traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && !traderequest.receivedByUserOwner && !traderequest.receivedByUserReuqest);
    }

    return (
        <div className='tradeRequestsContainer'>
            <CollectionDetail collection={collection} />

            <div className="collectionButtons">
                <button className="btn" onClick={() => history.push('/collection/' + collection.collectionId)}>Collectie</button> <button className="btn" onClick={() => history.push('/trade/' + collection.collectionId)}>Ruilen</button>
            </div>

            <div className="tradeRequests">

                <h1>Ruilverzoeken</h1>
                <p>Dit zijn al je ruilverzoeken.</p>

                {tradeRequests && tradeRequests.length > 0 ? (tradeRequests.map((traderequest) => (
                    <div key={traderequest.tradeRequestId} className={`traderequest ${IsActiveTrade(traderequest) ? "active" : "inactive"}`}>
                        <span className='displayUser'>{traderequest.user.displayName}</span>  {IsActiveTrade(traderequest) ? <img className='icon' src={require('../../images/trade.png')} /> : ''}
                        <div className='tradeImages'>
                            <div className='left'><img width='80px' src={configData.BLOB_COLLECTIONS_URL + collectionId + '/' + traderequest.collectionItemsWanted[0].number + '.jpg'} /></div>
                            <div className='right'><img width='80px' src={configData.BLOB_COLLECTIONS_URL + collectionId + '/' + traderequest.collectionItemsToGive[0].number + '.jpg'} /></div>
                        </div>
                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.RequestSent && traderequest.userOwnerAccountId == user.userAccountId && (
                            <span>
                                <p>Je stuurde een verzoek naar <b>{traderequest.user.displayName}</b> om jouw plaatje(s) met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} te ruilen
                            voor plaatje(s) met nummer {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])}</p>
                                <div className='buttonbar'><button className="btn" onClick={() => CancelTradeRequest(traderequest)}>Annuleer verzoek</button></div>
                            </span>)}

                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.RequestSent && traderequest.userOwnerAccountId != user.userAccountId && (
                            <span>
                                <p><b>{traderequest.user.displayName}</b> wil zijn/haar plaatje(s) met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} ruilen
                            voor jouw plaatje(s) met nummer {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])}</p>
                                <div className='buttonbar'><button className="btn accept" onClick={() => ApproveTradeRequest(traderequest)}>Accepteren</button>&nbsp;<button className="btn" onClick={() => RejectTradeRequest(traderequest)}>Afwijzen</button></div>
                            </span>)}

                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Canceled && (
                            <span>
                                <p>Je stuurde een verzoek naar <b>{traderequest.user.displayName}</b> om jouw plaatje(s) met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} te ruilen
                            voor plaatje(s) met nummer {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])}</p>
                                <div className='buttonbar'><span className="btn disabled">Je hebt je verzoek geannuleerd</span></div>
                            </span>)}

                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Rejected && traderequest.userOwnerAccountId == user.userAccountId && (
                            <span >
                                <p>Je stuurde een verzoek naar <b>{traderequest.user.displayName}</b> om jouw plaatje(s) met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} te ruilen
                            voor plaatje(s) met nummer {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])}</p>
                                <div className='buttonbar'><span className="btn disabled">Verzoek is afgewezen</span></div>
                            </span>)}


                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Rejected && traderequest.userOwnerAccountId != user.userAccountId && (
                            <span>
                                <p><b>{traderequest.user.displayName}</b> stuurde een verzoek om jouw plaatje(s) met nummer {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} te ruilen
                            voor plaatje(s) met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])}</p>
                                <div className='buttonbar'><span className="btn disabled">Je wees dit verzoek af</span></div>
                            </span>)}




                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && traderequest.userOwnerAccountId == user.userAccountId &&
                            (
                                (!traderequest.sentByUserOwner && (
                                    <span>
                                        Stuur de plaatje(s) met de nummers {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} naar:<br />
                                        <div className='postaladdress'>{traderequest.user.postName} <br />
                                            {traderequest.user.postAddres} <br />
                                            {traderequest.user.postCode} {traderequest.user.postCity}</div>
                                        <div className='buttonbarrelative'><button className="btn  accept" onClick={() => ItemsSend(traderequest)}>Ik heb de plaatjes verzonden</button></div>
                                    </span>)
                                )

                                ||
                                (traderequest.sentByUserOwner && !traderequest.receivedByUserRequest && (
                                    <span>
                                        <p>Je hebt je plaatjes met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} verstuurd naar <b>{traderequest.user.displayName}</b>.</p>
                                    </span>)
                                )
                                ||
                                (traderequest.sentByUserOwner && traderequest.receivedByUserRequest && (
                                    <span>
                                        <p>Je plaatjes met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} zijn ontvangen door <b>{traderequest.user.displayName}</b>.</p>
                                    </span>)
                                )
                            )


                        }

                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && traderequest.userOwnerAccountId == user.userAccountId &&
                            (
                                (!traderequest.sentByUserRequest && !traderequest.receivedByUserOwner && (<p>Je krijgt de plaatjes {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} toegestuurd door <b>{traderequest.user.displayName}</b>.</p>))
                                ||
                            (traderequest.sentByUserRequest && !traderequest.receivedByUserOwner && (<span><b>{traderequest.user.displayName}</b> heeft de plaatjes {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} toegestuurd.<div className='buttonbarrelative'><button className="btn accept" onClick={() => ItemsReceived(traderequest)}>Ik heb de plaatjes ontvangen</button></div></span>))
                                ||
                                (traderequest.sentByUserRequest && traderequest.receivedByUserOwner && (<p>Je hebt de plaatjes {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} ontvangen.</p>))
                            )
                        }
                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && traderequest.userOwnerAccountId == user.userAccountId && traderequest.receivedByUserOwner && traderequest.receivedByUserRequest &&
                            (<div className='buttonbar'><span className="btn disabled">Plaatjes uitgewisseld</span></div>)}



                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && traderequest.userOwnerAccountId != user.userAccountId &&
                            (
                                (!traderequest.sentByUserRequest && (
                                    <span>
                                        Stuur de plaatje(s) met de nummers {traderequest.collectionItemsWanted.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} naar:<br />
                                        <div className='postaladdress'>{traderequest.user.postName} <br />
                                            {traderequest.user.postAddres} <br />
                                            {traderequest.user.postCode} {traderequest.user.postCity}</div>
                                        <div className='buttonbarrelative'><button className="btn  accept" onClick={() => ItemsSend(traderequest)}>Ik heb de plaatjes verzonden</button></div>
                                    </span>)
                                )

                                ||
                            (traderequest.sentByUserRequest && !traderequest.receivedByUserOwner && (
                                    <span>
                                        <p>Je hebt je plaatjes met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} verstuurd naar <b>{traderequest.user.displayName}</b>.</p>
                                    </span>)
                                )
                                ||
                                (traderequest.sentByUserOwner && traderequest.receivedByUserRequest && (
                                    <span>
                                        <p>Je plaatjes met nummer {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} zijn ontvangen door <b>{traderequest.user.displayName}</b>.</p>
                                    </span>)
                                )
                            )


                        }

                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && traderequest.userOwnerAccountId != user.userAccountId &&
                            (
                            (!traderequest.sentByUserOwner && !traderequest.receivedByUserRequest && (<p>Je krijgt de plaatjes {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} toegestuurd door <b>{traderequest.user.displayName}</b>.</p>))
                                ||
                            (traderequest.sentByUserOwner && !traderequest.receivedByUserRequest && (<span><b>{traderequest.user.displayName}</b> heeft de plaatjes {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} toegestuurd.<div className='buttonbarrelative'><button className="btn accept" onClick={() => ItemsReceived(traderequest)}>Ik heb de plaatjes ontvangen</button></div></span>))
                                ||
                            (traderequest.sentByUserOwner && traderequest.receivedByUserRequest && (<p>Je hebt de plaatjes {traderequest.collectionItemsToGive.map(item => <b>{item.number}</b>).reduce((result, item) => [result, <div>, </div>, item])} ontvangen.</p>))
                            )
                        }
                        {traderequest.tradeStatus === configData.REQUEST_STATUS_ENUM.Approved && traderequest.userOwnerAccountId != user.userAccountId && traderequest.receivedByUserOwner && traderequest.receivedByUserRequest &&
                            (<div className='buttonbar'><span className="btn disabled">Plaatjes uitgewisseld</span></div>)}





                    </div>
                ))) : <div><i>Je hebt nog geen ruilverzoeken</i></div>
                }</div ></div>
    );
}

export default TradeRequests;