import React, { useState, useEffect } from 'react';
import configData from "../../config.json";
import { useHistory } from 'react-router-dom';

export const Collections = ({ onOpenCollection, isMyCollections, changedUserHasCollections }) => {
    const [collections, setCollections] = useState([]);
    const [user, setUser] = useState();
    const history = useHistory();

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {

        const loggedInUser = localStorage.getItem("user");
        var currentUser = null;

        if (loggedInUser) {
            currentUser = JSON.parse(loggedInUser)
            setUser(currentUser);
        }
        //onOpenCollection(collection, isMyCollections)
        var apiUrl = configData.SERVER_URL + 'collections/'

        if (isMyCollections)
            apiUrl += 'GetMyCollections/' + currentUser.userAccountId;
        else if (currentUser)
            apiUrl += 'GetActualCollectionsForUser/' + currentUser.userAccountId;
        else
            apiUrl += 'GetActualCollections'; 
        
        const response = await fetch(apiUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        
        if (changedUserHasCollections)
            changedUserHasCollections(data && data.length > 0);

        setCollections(data);
    }

    function onCollectionClick(collection) {
        if (isMyCollections)
            history.push('/collection/' + collection.collectionId);
        else
            onOpenCollection(collection)
    }

    return (
        <div>{
            collections.map((collection) => (
                <div key={collection.collectionId} className="collection" onClick={() => onCollectionClick(collection)}>
                    <b>{collection.name}</b>
                    <img src={configData.BLOB_COLLECTION_URL + collection.imageFile} />
                </div>
            ))
        }</div>
    );
}

export default Collections;

//import React, { useState, useEffect } from 'react';
////import axios from 'axios';

////import $ from 'jquery';

////const collectionList = [
////    {
////        title: 'Alberhein - voetbalhelden 2021',
////        imageFile: 'ah-voetbalhelden.jpg',
////    },
////    {
////        title: 'Jumbo - Fien en Teun',
////        imageFile: 'Fienenteun.png',
////    }
////]

//function Collections() {
//    useEffect = (()=> {
//        //const response = fetch('https://localhost:44324/api/collection');
//        //const data = await response.json;
//        //collectionList = data.results;

//        //collectionList: [...response.content]
//        //axios.get("https://localhost:44324/api/collection").then(
//        //    result => {
//        //        this.setState({
//        //            isLoaded: true,
//        //            items: result.data
//        //        });
//        //    }
//    }, [])

//    return (
//        <div>{
//            //collectionList.map((collection) => (
//            //    <div className="collection">
//            //        <b>{collection.title}</b>
//            //        <img src={require(`../images/${collection.imageFile}`)} />
//            //    </div>
//            //))
//        }</div>
//    )
//}


//export default Collections;



//export default class Collections extends React.Component {
//    constructor(props) {
//        super(props);

//        this.state = {
//            collectionList: []
//        }
//    }

//    render() {
//        return (
//            <div className="collection">
//                <Collection imageUrl={require("../images/ah-voetbalhelden.jpg")} />
//            </div>)
//    }


//    componentDidMount() {
//        this.fetch();
//    }

//    fetch() {
//        var context = this;

//        $.ajax({
//            url: 'https://localhost:44324/api/collection',
//            method: 'GET',
//            success: function (response) {
//                context.setState({
//                    collectionList: [...response.content]
//                });
//            }
//        });
//    }
//}
